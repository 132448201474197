import { uploadBase64File, uploadFile } from 'Axios/ApiCall'
import AlertGeneral from 'components/AlertGeneral/AlertGeneral'
import CommonButton from 'components/CommonButton'
import LoadingButton from 'components/LoadingButton'
import Paragraf from 'components/Paragraf'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectWorkOrder } from 'store/slices/appMiscSlice'
import { printToast } from 'utils/printToast'
import { setStepAmount } from 'store/slices/registrationStepper'
import { useQuery } from 'Hooks/useQuery'
import { populateWorkOrder } from 'store/slices/appMiscSlice'
import { LocalStorageService } from 'utils/LocalStorageService'
import { getUrlLexus, getUrlToyota } from 'utils/utils'

const ChoosePictureExplanation: React.FC = () => {
  const [picture, setPicture] = useState<any>({})
  const inputFileRefPicture = useRef<HTMLInputElement>()
  const [uploadingProcess, setUploadingProgress] = useState(0)
  const [loadingApi, setLoadingApi] = useState(false)
  const [errorApi, setErrorApi] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const workOrder = useSelector(selectWorkOrder)
  const [isFromHomePage, setIsFromHomePage] = React.useState(true)
  const query = useQuery()
  const [urlLexus, setUrlLexus] = React.useState("")
  const [urlToyota, setUrlToyota] = React.useState("")
  useEffect(() => {
    getUrlLexus().then(isLexus => {
      setUrlLexus(isLexus)
    })
    getUrlToyota().then(isLexus => {
      setUrlToyota(isLexus)
    })
  }, [])

  useEffect(() => {
    if (Object.keys(picture).length) {
      setIsFromHomePage(false)
      setLoadingApi(true)
      uploadFile(
        picture.file,
        e => setUploadingProgress((e.loaded / e.total) * 100),
        picture.type,
      )
        .then(response => {
          setLoadingApi(false)
          setUploadingProgress(0)
          dispatch(
            populateWorkOrder({
              workOrder: response.data,
              status: 'uncompleted',
            }),
          )
          const workOrder = response.data
          redirectToRightAppBrand(workOrder)
          LocalStorageService.addObject({ workOrder: response.data })
          setPicture({})
        })
        .catch(e => {
          const status = e.response?.status || 0;
          if (status != 403) {
            setLoadingApi(false)
            const message = e?.response?.data?.messages[0]?.message
            if (message) {
              printToast.error(message, 6000)
            } else {
              setErrorApi(true)
            }
            setUploadingProgress(0)
            setPicture({})
          } else {
            uploadBase64File(
              picture.file,
              e => setUploadingProgress((e.loaded / e.total) * 100),
              picture.type,
              setLoadingApi,
              setErrorApi,
              setUploadingProgress,
              setPicture,
              dispatch,
              populateWorkOrder,
              redirectToRightAppBrand
            )
          }
          //LocalStorageService.removeObject('workOrder')
        })
    }
  }, [picture, dispatch])

  function redirectToRightAppBrand(workOrder) {
    if (workOrder && Object.keys(workOrder).length) {
      const workOrderId = workOrder.code + '/' + workOrder.dealerCode
      if (workOrder.workOrder.includes('TCU')) {
        window.location.replace(` ${urlToyota}recap/?workOrderId=${workOrderId}`)
      }
      if (workOrder.workOrder.includes('LCU')) {
        window.location.replace(` ${urlLexus}recap/?workOrderId=${workOrderId}`)
      }
    }
  }


  const onClickButtonPicture = () => {
    if (inputFileRefPicture.current) inputFileRefPicture.current.click()
  }

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description="Errore per favore riprovare"
        />
      )}
      <div className="mb-2 text-left w-full px-5 pb-5">
        <Paragraf name="Appoggia il documento su una superfice piana, inquadra tutto l'ordine di lavoro, metti a fuoco e scatta la foto" />
        <div className="mt-8">
          {loadingApi ? (
            <>
              <LoadingButton />
              <Paragraf name="Caricamento del documento in corso..." />
            </>
          ) : (
            <>
              <CommonButton
                onClick={onClickButtonPicture}
                type="button"
                name="Scatta foto"
              />
              <input
                type="file"
                capture="environment"
                style={{ display: 'none' }}
                ref={inputFileRefPicture}
                onChange={async e => {
                  setPicture({ file: e.target.files[0], type: 'IMAGE' })
                }}
              />
              <CommonButton
                cssType='fill-in-white'
                onClick={() => {
                  dispatch(
                    dispatch(
                      populateWorkOrder({ workOrder: null, status: null }),
                    ),
                  )
                  navigate('/')
                }}
                type="button"
                name="Indietro"
              />
            </>
          )}
          <div>
            {uploadingProcess ? (
              <span className="text-third">
                Progresso caricamento: {uploadingProcess.toFixed(0)} %
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default ChoosePictureExplanation
