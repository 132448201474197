import { AxiosResponse } from 'axios'
import { easyPayInstance } from './AxiosInstance'
import { printToast } from 'utils/printToast';
import { LocalStorageService } from 'utils/LocalStorageService';

let appId = "TOYOTA"
let url = window.location.href;

export const uploadFile = (data, onUploadProgress, type) => {
  const formData = new FormData()
  formData.append('wordOrder', data)
  return easyPayInstance.post(`/${appId}/workOrder/${type}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const uploadBase64File = (data, onUploadProgress, type, setLoadingApi, setErrorApi, setUploadingProgress, setPicture, dispatch, populateWorkOrder,redirectToRightAppBrand) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    easyPayInstance.post(`/${appId}/workOrder/`, {
        content: reader.result.toString().replace(/^data:(.*,)?/, '').split(',').toString(),
        contentType: type
    }).then(response => {
      setLoadingApi(false)
      setUploadingProgress(0)
      dispatch(
        populateWorkOrder({
          workOrder: response.data,
          status: 'uncompleted',
        }),
      )
      const workOrder = response.data
      redirectToRightAppBrand(workOrder)
      LocalStorageService.addObject({ workOrder: response.data })
      setPicture({})
    }).catch(e => {
      setLoadingApi(false)
      if (e?.response?.data?.messages !== undefined && e?.response?.data?.messages !== null) {
        printToast.error(e?.response?.data?.messages[0]?.message, 6000)
      } else if (e?.response?.data !== undefined && e?.response?.data !== null) {
        printToast.error(e?.response?.data, 6000)
      } else {
        setErrorApi(true)
      }

      setUploadingProgress(0)
      setPicture({})
      'workOrder' in (LocalStorageService.getObject() ?? {}) &&
        LocalStorageService.removeObject('workOrder')
    })
  };
  reader.readAsDataURL(data);
}
